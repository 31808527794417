import CookieConsent from "./consent";
import CookieConsentConstructor from "./constructor";

var cookieConsentConstructor = new CookieConsentConstructor({
  message:
    'We use cookies to provide you the best possible experience. For more information, please see our <a href="/pages/privacy-policy">privacy policy</a>.',
  accept: "I am happy to proceed",
  fields: {
    necessary: "Necessary",
    preferences: "Preferences",
    marketing: "Marketing",
    statistics: "Statistics",
  },
  settings: {
    // revision: 0,
    // requireClick: true,
    // domain: 'localhost'
  },
});

window.cookieConsent = new CookieConsent();

var initializer;
if (typeof Turbolinks == "undefined") {
  initializer = "DOMContentLoaded";
} else {
  initializer = "turbolinks:load";

  document.addEventListener(
    "turbolinks:before-visit",
    cookieConsent.deactivate
  );
}

document.addEventListener(initializer, cookieConsent.init);

cookieConsent.on("formRequired", function () {
  cookieConsentConstructor.init();
});

cookieConsent.on("reset", function () {
  console.log("Resetting consents...");
});

cookieConsent.on("init", function (consents) {
  console.log(consents);
});

/*
cookieConsent.on('complete', function (consents) {
  console.log(consents);
});
*/
